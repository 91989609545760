import React, { FC, memo } from 'react';
import { FormControl } from 'react-bootstrap';
import { staticImagesRelativePath } from '../../../utils/staticImagesRelativePath';
import cls from './TariffSetConnectionLimits.module.scss';
import { TariffIntegrationLimitType } from '../tariff.types';
import { checkAndReturnIconForIntegration } from '../../../utils/checkAndReturnIconForIntegration';

type TariffSetConnectionLimitsProps = {
  integrations: TariffIntegrationLimitType[];
  setIntegrationsLimits: React.Dispatch<React.SetStateAction<TariffIntegrationLimitType[]>>;
};

const TariffSetConnectionLimits: FC<TariffSetConnectionLimitsProps> = memo((props) => {
  const { integrations, setIntegrationsLimits } = props;
  const [allConnection, setAllConnection] = React.useState(-1);

  return (
    <div className={cls.Container}>
      <div className={cls.Header}>
        <h4 className="title-4">Подключения</h4>
        <p className={cls.Subtitle}>Введите -1 для неограниченного количества</p>
      </div>
      <div className={cls.Connection}>
        <label htmlFor="allConnection">Установить для всех</label>
        <FormControl
          type="text"
          id="allConnection"
          value={allConnection.toString()}
          onChange={(e) => {
            if (
              typeof Math.abs(Number(e.target.value)) === 'number' &&
              !isNaN(Math.abs(Number(e.target.value)))
            ) {
              setAllConnection(Number(e.target.value));
              setIntegrationsLimits(
                integrations.map((i) => {
                  return { ...i, limit: Number(e.target.value) };
                }),
              );
            }
          }}
          className={cls.ConnectionInput}
        />
      </div>
      <div className={cls.Connections}>
        {integrations.map((integration) => (
          <div key={integration.id} className={cls.Connection}>
            <label htmlFor={integration.id} className={cls.AboutIntegration}>
              <img
                src={staticImagesRelativePath.integrationIcons[
                  checkAndReturnIconForIntegration(integration.id)
                ]()}
                alt={integration.name}
              />
              <span className="regular-text">{integration.name}</span>
            </label>
            <FormControl
              type="text"
              value={integration.limit}
              id={integration.id}
              onChange={(e) => {
                if (
                  typeof Math.abs(Number(e.target.value)) === 'number' &&
                  !isNaN(Math.abs(Number(e.target.value)))
                ) {
                  setIntegrationsLimits(
                    integrations.map((i) => {
                      if (i.id === integration.id) {
                        return { ...i, limit: Number(e.target.value) };
                      }
                      return i;
                    }),
                  );
                }
              }}
              className={cls.ConnectionInput}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default TariffSetConnectionLimits;
